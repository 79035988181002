import './App.css';
import banner from './images/banner.png';
import accreditations from './images/accreditations.png';
import textMessage1 from './images/textiphone.png';
import textMessage2 from './images/textandroid.png';
import testimonials from './images/testimonials.png';
import fhfooterbanner from './images/fhfooterbanner.png';
import aboutus from './images/aboutus.png';
import buyer from './images/buyer.jpg';
import sold from './images/sold.jpg';
import services from './images/services.png';

function App() {
  const scrollToSubscription = (e) => {
    e.preventDefault();
    document.getElementById('subscription-form').scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  const scrollToSection = (e, id) => {
    e.preventDefault();
    document.querySelector(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  return (
    <div className="App" style={{maxWidth: "1440px", margin: "0 auto"}}>
      <nav style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: 'rgba(0, 35, 102, 0.9)',
        padding: '15px 0',
        zIndex: 1000,
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
      }}>
        <ul style={{
          listStyle: 'none',
          display: 'flex',
          justifyContent: 'center',
          gap: '40px',
          margin: 0,
          padding: 0,
          maxWidth: "1440px",
          margin: "0 auto"
        }}>
          <li><a href="#testimonials" style={{color: '#ffffff', textDecoration: 'none', padding: '8px 16px', borderRadius: '4px', transition: 'background-color 0.3s', fontSize: '1.1rem'}} onClick={(e) => scrollToSection(e, '#testimonials')}>Testimonials</a></li>
          <li><a href="#about" style={{color: '#ffffff', textDecoration: 'none', padding: '8px 16px', borderRadius: '4px', transition: 'background-color 0.3s', fontSize: '1.1rem'}} onClick={(e) => scrollToSection(e, '#about')}>About Us</a></li>
          <li><a href="#services" style={{color: '#ffffff', textDecoration: 'none', padding: '8px 16px', borderRadius: '4px', transition: 'background-color 0.3s', fontSize: '1.1rem'}} onClick={(e) => scrollToSection(e, '#services')}>Services</a></li>
          <li><a href="#contact" style={{color: '#ffffff', textDecoration: 'none', padding: '8px 16px', borderRadius: '4px', transition: 'background-color 0.3s', fontSize: '1.1rem'}} onClick={(e) => scrollToSection(e, '#contact')}>Contact Us</a></li>
        </ul>
      </nav>

      <header className="hero" style={{maxWidth: "1200px", margin: "0 auto", padding: "0 20px"}}>
        <img src={banner} alt="Fetch Houses Banner" style={{width: "100%", marginBottom: "3rem", maxHeight: "600px", objectFit: "cover"}} />
        <h1 style={{fontSize: "2.5rem", marginBottom: "1.5rem"}}>Your Trusted Partner in Real Estate Solutions!</h1>
        <p style={{fontSize: "1.25rem", maxWidth: "800px", margin: "0 auto 2rem"}}>Connecting Buyers and Sellers with Speed and Transparency – Discover How We Can Help You Maximize Your Property's Potential Today!</p>
        <a href="/cash-offer">
          <button className="cta-button" style={{backgroundColor: '#FF6B00', fontSize: "1.2rem", padding: "12px 24px"}}>Get Cash Offer Now</button>
        </a>
      </header>

      <section id="testimonials" className="testimonials" style={{maxWidth: "1200px", margin: "4rem auto", padding: "0 20px"}}>
        <img src={testimonials} alt="Customer Testimonials" style={{width: "100%", marginBottom: "2rem", maxHeight: "400px", objectFit: "contain"}} />
        <div style={{display: "flex", justifyContent: "center", gap: "40px"}}>
          <img src={textMessage1} alt="Text Message Testimonial 1" style={{maxWidth: "400px", width: "45%"}} />
          <img src={textMessage2} alt="Text Message Testimonial 2" style={{maxWidth: "400px", width: "45%"}} />
        </div>
      </section>

      <section id="about" className="program-overview" style={{maxWidth: "1200px", margin: "4rem auto", padding: "0 20px"}}>
        <img src={aboutus} alt="About Us" style={{width: "100%", maxWidth: "1000px", marginBottom: "3rem"}} />
        <div className="features" style={{display: "flex", gap: "40px", justifyContent: "center"}}>
          <div className="feature" style={{maxWidth: "500px", width: "45%"}}>
            <img src={buyer} alt="Buyer" style={{width: "100%", height: "auto"}} />
          </div>
          <div className="feature" style={{maxWidth: "500px", width: "45%"}}>
            <img src={sold} alt="Sold" style={{width: "100%", height: "auto"}} />
          </div>
        </div>
      </section>

      <section id="services" style={{maxWidth: "1200px", margin: "4rem auto", padding: "0 20px"}}>
        <img src={services} alt="Our Services" style={{width: "100%", marginBottom: "3rem", maxHeight: "600px", objectFit: "cover"}} />
        <h2 style={{textAlign: "center", fontSize: "2rem", marginBottom: "2rem"}}>Click the button below to get 100% funding for your next deal!</h2>
        <div style={{textAlign: "center"}}>
          <button className="cta-button" style={{backgroundColor: '#FF6B00', fontSize: "1.2rem", padding: "12px 24px"}}>Learn More</button>
        </div>
      </section>

      <section id="subscription-form" style={{padding: "3rem", backgroundColor: "#f5f5f5", borderRadius: "10px", margin: "4rem auto", maxWidth: "800px"}}>
        <h2 style={{textAlign: "center", marginBottom: "2rem", fontSize: "2rem"}}>Subscribe to our newsletter!</h2>
        <form style={{display: "flex", flexDirection: "column", gap: "1.5rem", maxWidth: "600px", margin: "0 auto"}}>
          <input 
            type="text" 
            placeholder="Your Name"
            style={{padding: "1rem", borderRadius: "5px", border: "1px solid #ddd", fontSize: "1.1rem"}}
          />
          <input 
            type="email" 
            placeholder="Your Email"
            style={{padding: "1rem", borderRadius: "5px", border: "1px solid #ddd", fontSize: "1.1rem"}}
          />
          <input 
            type="tel" 
            placeholder="Your Phone Number"
            style={{padding: "1rem", borderRadius: "5px", border: "1px solid #ddd", fontSize: "1.1rem"}}
          />
          <button 
            className="cta-button" 
            style={{marginTop: "1.5rem", backgroundColor: '#FF6B00', fontSize: "1.2rem", padding: "12px 24px"}}
          >
            Subscribe
          </button>
        </form>
      </section>

      <img src={accreditations} alt="Accreditations" style={{maxWidth: "1000px", width: "90%", margin: "0 auto 3rem", display: "block"}} />

      <footer style={{position: "relative", marginTop: "60px"}}>
        <div style={{width: "100%", maxWidth: "1200px", margin: "0 auto", padding: "0 20px"}}>
          <div style={{position: "relative"}}>
            <img src={fhfooterbanner} alt="Fetch Houses Footer Banner" style={{width: "100%", maxHeight: "400px", objectFit: "cover"}} />
            <div style={{position: "absolute", top: "75%", left: "50%", transform: "translate(-50%, -50%)", width: "100%"}}>
              <nav style={{marginBottom: "30px", paddingLeft: "100px"}}>
                <ul style={{
                  listStyle: 'none', 
                  padding: 0, 
                  margin: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '20px'
                }}>
                  <li><a href="#testimonials" style={{color: '#ffffff', textDecoration: 'none', fontSize: '1.2rem', fontFamily: 'Cooper Hewitt Thin'}} onClick={(e) => scrollToSection(e, '#testimonials')}>Testimonials</a></li>
                  <li><a href="#about" style={{color: '#ffffff', textDecoration: 'none', fontSize: '1.2rem', fontFamily: 'Cooper Hewitt Thin'}} onClick={(e) => scrollToSection(e, '#about')}>About Us</a></li>
                  <li><a href="#services" style={{color: '#ffffff', textDecoration: 'none', fontSize: '1.2rem', fontFamily: 'Cooper Hewitt Thin'}} onClick={(e) => scrollToSection(e, '#services')}>Services</a></li>
                  <li><a href="#contact" style={{color: '#ffffff', textDecoration: 'none', fontSize: '1.2rem', fontFamily: 'Cooper Hewitt Thin'}} onClick={(e) => scrollToSection(e, '#contact')}>Contact Us</a></li>
                </ul>
              </nav>
              <div style={{textAlign: "center"}}>
                <small style={{color: '#000000', fontSize: "1rem"}}>© 2024 Fetch Houses LLC. All rights reserved. All content, logos, and intellectual property on this site are protected by copyright laws.</small>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
